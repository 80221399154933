import React from "react"
import { Carousel, Container, Row, Col } from "react-bootstrap"
import quotes from "../../images/slicing/home/quotes.png"
import testimonialBg from "../../images/slicing/service/testimonialbg.png"
import { graphql, StaticQuery } from "gatsby"
const backgroundImage = {
  backgroundImage: `url(${testimonialBg})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}
const TestimonialComponent = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Container fluid style={{ background: "#f7f6f9" }}>
            <Container fluid style={backgroundImage}>
              <Container className="pt-3">
                <Row>
                  <Col className="carousel-columns">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="line"></div>
                      <div>
                        <h6 className="carousel-section-subheading text-center">
                          WHAT OUR CLIENT SAY
                        </h6>
                      </div>
                      <div className="line"></div>
                    </div>
                    <h2 className="text-center">TESTIMONIAL</h2>
                  </Col>
                </Row>
              </Container>

              <Carousel
                className="case-study-section"
                interval={4000}
                controls={false}
                indicators={data.allContentfulTestimonials.totalCount > 3 ? false : true}
                defaultActiveIndex={0}
              >
                {data &&
                  data.allContentfulTestimonials.nodes &&
                  data.allContentfulTestimonials.nodes.map(
                    (carouselItem, index) => (
                      <Carousel.Item key={index + "testimonial"}>
                        <Container fluid>
                          <Row className="p-5 px-2">
                            <Col className="carousel-columns">
                              <Row>
                                {/* <Col md="5" xs="4" sm="4">
                                  <div className="quotes-image">
                                    <img
                                      src={quotes}
                                      style={{ transform: "rotateY(180deg)" }}
                                      className="testimonial-image"
                                      alt="Not Found"
                                    />
                                  </div>
                                </Col> */}
                                <Col md="12" xs="12" sm="12" className="text-center">
                                  <img
                                    src={
                                      carouselItem.clientImage &&
                                      carouselItem.clientImage.file.url
                                    }
                                    width="100px"
                                    height="100px"
                                    style={{ borderRadius: "50%" }}
                                    alt="Not Found"
                                    className="testimonial-client"
                                  />
                                </Col>
                              </Row>
                              <Row className=" testimonial-client-detail mt-2">
                                <Col>
                                  <p
                                    className="font-italic testimonial"
                                    style={{ fontSize: "1rem" }}
                                  >
                                    {carouselItem.description &&
                                      carouselItem.description.description}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="px-5 mt-4 testimonial-client-detail">
                                <Col className="text-center">
                                  <h5 className="text-uppercase font-weight-bold text-std" >
                                    {carouselItem.clientName}
                                  </h5>
                                  <h6
                                    className="text-uppercase text-std"

                                  >
                                    {carouselItem.clientPosition}
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Carousel.Item>
                    )
                  )}
              </Carousel>
            </Container>
          </Container>
        )
      }}
    ></StaticQuery>
  )
}

export default TestimonialComponent
export const query = graphql`
  {
    allContentfulTestimonials {
      nodes {
        clientName
        clientPosition
        contentful_id
        createdAt
        description {
          description
        }
        clientImage {
          file {
            url
          }
        }
      }
      totalCount
    }
  }
`
